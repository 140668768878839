/** Derives them most dominant colors from the image using K-means algorithm
 * @param {String} url url to the image
 * @param {Number} k positive number of output colors
 * @param {number} kickTreshhold percentage threshold for kicking out unpopular colors, if set to null, then output has all k colors
 * @returns {Promise} array of RGB colors */
export default function kMeansPallete(bitmap, k = 5, kickTreshhold = null) {
    const MAX_ITERATIONS = 50
    const EPS = 0.001

    // HELPERS
    function getLuminosity(r, g, b) {
        return Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b)
    }
    function colorDistance(pixel1, pixel2) {
        let rMean = (pixel1[0] + pixel2[0]) / 2,
            rDiff = pixel1[0] - pixel2[0],
            bDiff = pixel1[1] - pixel2[1],
            gDiff = pixel1[2] - pixel2[2]
        return Math.sqrt(
            ((512 + rMean) * rDiff * rDiff) / 128 +
                4 * gDiff * gDiff +
                ((767 - rMean) * bDiff * bDiff) / 128
        )
    }
    function sliceIntoChunks(arr, chunksNum) {
        const size = Math.ceil(arr.length / chunksNum)
        return Array.from({ length: chunksNum }, (v, i) =>
            arr.slice(i * size, i * size + size)
        )
    }

    // Select initial centroids
    function getNaiveShardingCentroids(img, k) {
        let imgWithLuminosities = []
        // Skip alpha channel
        for (let i = 0; i < img.length; i += 4)
            imgWithLuminosities.push([
                img[i],
                img[i + 1],
                img[i + 2],
                getLuminosity(img[i], img[i + 1], img[i + 2]),
            ])
        imgWithLuminosities = imgWithLuminosities.sort(
            (pixel1, pixel2) => pixel1[3] - pixel2[3]
        )
        return sliceIntoChunks(imgWithLuminosities, k)
            .map((chunk) => getPixelsMean(chunk))
            .map((preCentroid) =>
                preCentroid.slice(0, 3).map((channel) => parseInt(channel))
            )
    }

    // Get labels (associated centroids) for each point
    function getLabels(img, centroids) {
        const labels = {}
        for (let c = 0; c < centroids.length; c++) {
            labels[c] = {
                points: [],
                centroid: centroids[c],
            }
        }
        for (let i = 0; i < img.length; i += 4) {
            const curPixel = [img[i], img[i + 1], img[i + 2]]
            let closestCentroid,
                closestCentroidIndex = 0,
                prevDistance = 255 * 255 * 3 + 1
            for (let j = 0; j < centroids.length; j++) {
                let centroid = centroids[j]
                if (j === 0) {
                    closestCentroid = centroid
                    closestCentroidIndex = j
                    prevDistance = colorDistance(curPixel, closestCentroid)
                } else {
                    // get distance:
                    const distance = colorDistance(curPixel, centroid)
                    if (distance < prevDistance) {
                        prevDistance = distance
                        closestCentroid = centroid
                        closestCentroidIndex = j
                    }
                }
            }
            // add point to centroid labels:
            labels[closestCentroidIndex].points.push(curPixel)
        }
        return labels
    }

    function getPixelsMean(pixelList) {
        const sums = pixelList.reduce((acc, cur) =>
            acc.map((channel, index) => channel + cur[index])
        )
        return sums.map((channel) => channel / pixelList.length)
    }

    function recalculateCentroids(img, labels) {
        let newCentroid,
            newCentroidList = []
        for (const k in labels) {
            const centroidGroup = labels[k]
            if (centroidGroup.points.length > 0)
                newCentroid = getPixelsMean(centroidGroup.points)
            else newCentroid = getNaiveShardingCentroids(img, 1)[0]
            newCentroidList.push(newCentroid)
        }
        return newCentroidList
    }

    function compareCentroids(centroid1, centroid2) {
        return centroid1.every(
            (centroidEl1, index) =>
                Math.abs(centroidEl1 - centroid2[index]) < EPS
        )
    }

    function shouldStop(oldCentroids, centroids, iterations) {
        if (!oldCentroids || !oldCentroids.length) return false
        return (
            iterations > MAX_ITERATIONS ||
            oldCentroids.every((oldCentroid, index) =>
                compareCentroids(oldCentroid, centroids[index])
            )
        )
    }

    function calcKMeans(img, k) {
        let oldCentroids = [],
            labels,
            centroids = getNaiveShardingCentroids(img, k),
            iterations = 0

        getNaiveShardingCentroids(img, 5)
        while (!shouldStop(oldCentroids, centroids, iterations)) {
            oldCentroids = centroids.slice()
            labels = getLabels(img, centroids)
            centroids = recalculateCentroids(img, labels)
            iterations++
        }

        let colors = centroids.map((color) =>
            color.map((channel) => Math.round(channel))
        )

        if (kickTreshhold) {
            const numPixels = img.length / 4
            let colorsToRemoveIds = []
            Object.entries(labels).forEach((el) => {
                const key = el[0]
                const value = el[1]
                if ((value.points.length / numPixels) * 100 < kickTreshhold)
                    colorsToRemoveIds.push(parseInt(key))
            })
            colors = colors.filter(
                (color, index) => !colorsToRemoveIds.includes(index)
            )
        }

        return {
            colors: colors,
            clusterSizes: Object.values(labels).map((label) =>
                ((label.points.length / img.length) * 4 * 100).toFixed(2)
            ),
        }
    }
    return calcKMeans(bitmap, k)
}
